import * as React from "react"
import { graphql } from "gatsby"
import { PaddedContent, PageWrapper } from "../components/modern/layout"
import IntroSection from "../components/homepage/intro"
import ProjectsSection from "../components/homepage/projects"
import BlogPostsSection from "../components/homepage/blogposts"
import AboutModusSection from "../components/homepage/about-modus"

export const query = graphql`
  query {
    allProjectsYaml {
      nodes {
        projects {
          active
          description
          name
          subtitle
          logo {
            publicURL
          }
          slug
          upstream
          tags
          url
        }
        featured {
          homepage {
            slug
          }
        }
      }
    }
    articles: allMarkdownRemark(
      sort: { fields: fields___date, order: DESC }
      limit: 3
      filter: { fields: { slug: { regex: "/^/blog/" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`

type Project = {
  name: string
  slug: string
  subtitle?: string
  upstream?: boolean
  active?: boolean
  logo?: { publicURL: string }
  tags?: string[]
  description: string
  url: string
}

type BlogPost = {
  title: string
  slug: string
  description: string
}

const HomePage = ({ data }) => {
  // Fetch data
  const allProjects: Project[] = data.allProjectsYaml.nodes[0].projects
  const homepageProjectsSlugs: { slug: string }[] =
    data.allProjectsYaml.nodes[0].featured.homepage
  const homepageProjects: Project[] = allProjects.filter(project =>
    homepageProjectsSlugs.find(
      homepageProject => homepageProject.slug === project.slug
    )
  )
  const blogposts: BlogPost[] = data.articles.edges.map(edge => ({
    title: edge.node.frontmatter.title,
    slug: edge.node.fields.slug,
    description: edge.node.frontmatter.description,
  }))

  // Rendered component
  return (
    <PageWrapper title="Software Innovation Lab" pathname="/">
      <PaddedContent background="white">
        <IntroSection />
      </PaddedContent>
      <PaddedContent background="yellow">
        <ProjectsSection projects={homepageProjects} />
      </PaddedContent>
      <PaddedContent background="purple">
        <BlogPostsSection blogposts={blogposts} />
      </PaddedContent>
      <PaddedContent customSx={{ position: `relative` }} background="white">
        <AboutModusSection />
      </PaddedContent>
    </PageWrapper>
  )
}

export default HomePage
