import * as React from "react"
import { Flex, Heading } from "theme-ui"
import { SimpleButton } from "../modern/buttons"
import { CardSet } from "../modern/cards"

type BlogPost = {
  title: string
  slug: string
  description: string
}

const BlogPostsSection: React.FC<{ blogposts: BlogPost[] }> = ({
  blogposts,
}) => {
  return (
    <Flex
      sx={{
        marginY: `2em`,
        alignItems: `center`,
        flexDirection: `column`,
        gap: `6em`,
        fontSize: `1em`,
      }}
    >
      <Heading
        sx={{
          textTransform: `uppercase`,
          fontSize: `3em`,
          textAlign: `center`,
          color: `white`,
        }}
      >
        Featured on our blog
      </Heading>
      <CardSet
        cards={blogposts.map(blogpost => ({
          slug: blogpost.slug,
          content: {
            title: blogpost.title,
            description: blogpost.description,
            button: {
              label: `Read more`,
              src: blogpost.slug,
            },
          },
        }))}
      />
      <SimpleButton
        label="View all posts"
        url="/blog"
        theme={{
          backgroundColor: `white`,
          textColor: `purple`,
          size: `big`,
        }}
      />
    </Flex>
  )
}

export default BlogPostsSection
