import * as React from "react"
import { Flex, Image, Paragraph, Heading, Box } from "theme-ui"
import mcSideImage from "../../images/target.svg"
import Shape2 from "../../images/yellow-shape.svg"
import Shape3 from "../../images/blue-green-shape.svg"
import { SimpleButton } from "../../components/modern/buttons"

const AboutModusSection: React.FC = () => (
  <Box
    sx={{
      "::before": {
        content: `''`,
        position: `absolute`,
        bottom: `0`,
        right: `-185px`,
        background: `url(${Shape2}) no-repeat`,
        backgroundSize: `cover`,
        display: [`none`, null, `inline-block`],
        height: `500px`,
        width: `790px`,
        zIndex: `1`,
      },
      "::after": {
        content: `''`,
        position: `absolute`,
        bottom: `55px`,
        left: `-160px`,
        background: `url(${Shape3}) no-repeat`,
        backgroundSize: `cover`,
        display: [`none`, null, `inline-block`],
        height: [`225px`, null, `680px`],
        width: [`190px`, null, `530px`],
        zIndex: `1`,
      },
    }}
  >
    <Flex
      sx={{
        columnGap: `8em`,
        rowGap: `4em`,
        flexWrap: `wrap`,
        alignItems: `center`,
        justifyContent: `center`,
        marginY: `5em`,
        flexDirection: [`column-reverse`, `row`, `row`],
      }}
    >
      <Flex
        sx={{
          flexDirection: `column`,
          gap: `3em`,
        }}
      >
        <Heading
          sx={{
            textTransform: `uppercase`,
            fontWeight: `bold`,
            fontSize: `3em`,
            maxWidth: [null, `7em`],
          }}
        >
          Tweag By Modus Create
        </Heading>
        <Paragraph
          sx={{
            fontSize: `1.6em`,
            maxWidth: `20em`,
          }}
        >
          Tweag is the open source program office (OSPO) of Modus Create, a
          global digital consulting firm that helps enterprises build
          competitive advantage through digital innovation. Our research enables
          solutions to big challenges in software development.
        </Paragraph>
        <SimpleButton
          label="About Modus Create"
          url="https://moduscreate.com/about/"
          theme={{
            backgroundColor: `black`,
            textColor: `white`,
            size: `big`,
          }}
        />
      </Flex>
      <Image
        src={mcSideImage}
        sx={{
          maxWidth: `25em`,
          flexShrink: 1,
        }}
      />
    </Flex>
    <Flex
      sx={{
        rowGap: `2em`,
        flexWrap: `wrap`,
        alignItems: `center`,
        flexDirection: `column`,
        mt: [`5em`, null, `15em`],
        mb: [`5em`, null, `10em`],
      }}
    >
      <Heading
        sx={{
          textTransform: `uppercase`,
          fontWeight: `bold`,
          fontSize: `3em`,
          zIndex: 10,
        }}
      >
        Interested in learning more?
      </Heading>
      <SimpleButton
        label="Contact Us"
        url="https://www.tweag.io/contact/"
        theme={{
          size: `big`,
        }}
      />
    </Flex>
  </Box>
)

export default AboutModusSection
