import * as React from "react"
import { Flex, Heading, Image, Paragraph } from "theme-ui"
import homeAnimation from "../../images/home.svg"
import { SimpleButton } from "../modern/buttons"

const IntroSection: React.FC = () => (
  <Flex
    sx={{
      columnGap: `10em`,
      rowGap: `4em`,
      flexWrap: `wrap`,
      alignItems: `center`,
      justifyContent: `center`,
      marginY: `5em`,
    }}
  >
    <Image
      src={homeAnimation}
      sx={{
        maxWidth: `25em`,
        flexShrink: 1,
      }}
    />
    <Flex
      sx={{
        flexDirection: `column`,
        gap: `3em`,
      }}
    >
      <Heading
        sx={{
          textTransform: `uppercase`,
          fontSize: `3em`,
          maxWidth: `14em`,
        }}
      >
        Improving the craft of software engineering
      </Heading>
      <Paragraph
        sx={{
          fontSize: `1.6em`,
          maxWidth: `20em`,
        }}
      >
        We are on a mission to improve developer experience (DX) and
        data-centric workflows (MLOps). Tweag is a collaborative space where
        engineers and researchers join forces to apply new ideas — all as open
        source.
      </Paragraph>
      <Flex
        sx={{
          gap: `2em`,
          flexWrap: `wrap`,
        }}
      >
        <SimpleButton
          label="Explore projects"
          url="/opensource"
          theme={{
            size: `big`,
          }}
        />
        {/*
        <SimpleButton
          label="Join us"
          url="https://moduscreate.com/careers"
          theme={{
            backgroundColor: `white`,
            textColor: `black`,
            size: `big`,
            border: true,
          }}
        />
        */}
      </Flex>
    </Flex>
  </Flex>
)

export default IntroSection
