import * as React from "react"
import { Flex, Heading, Image, Paragraph } from "theme-ui"
import { SimpleButton } from "../modern/buttons"
import placeholderLogo from "../../images/project_logo_placeholder.svg"

// Projects as they appear in the YAML file
type Project = {
  name: string
  slug: string
  subtitle?: string
  // `upstream` is `true` if we contribute to an upstream project or `false` if a home project
  upstream?: boolean
  active?: boolean
  logo?: { publicURL: string }
  tags?: string[]
  description: string
  url: string
}

const Project: React.FC<{ project: Project }> = ({ project }) => (
  <a href={project.url}>
    <Flex
      sx={{
        alignItems: `flex-start`,
        gap: `2em`,
      }}
    >
      <Image
        src={project.logo ? project.logo.publicURL : placeholderLogo}
        sx={{
          width: `5em`,
          height: `5em`,
          objectFit: `contain`,
          flexGrow: 0,
          flexShrink: 0,
        }}
      />
      <Flex
        sx={{
          flexDirection: `column`,
          gap: `1em`,
        }}
      >
        <Paragraph
          sx={{
            fontSize: `1.2em`,
            fontWeight: `bold`,
            color: `black`,
          }}
        >
          {project.name}
        </Paragraph>
        <Paragraph
          sx={{
            color: `black`,
          }}
        >
          {project.description}
        </Paragraph>
      </Flex>
    </Flex>
  </a>
)

const ProjectsSection: React.FC<{ projects: Project[] }> = ({ projects }) => (
  <Flex
    sx={{
      columnGap: `10em`,
      rowGap: `4em`,
      flexWrap: `wrap`,
      alignItems: `center`,
      justifyContent: `center`,
      marginY: `5em`,
      fontSize: `1em`,
    }}
  >
    <Flex
      sx={{
        flexDirection: `column`,
        gap: `3em`,
      }}
    >
      <Heading
        sx={{
          textTransform: `uppercase`,
          fontSize: `3em`,
        }}
      >
        Featured Projects
      </Heading>
      <Paragraph
        sx={{
          fontSize: `1.3em`,
          maxWidth: `20em`,
        }}
      >
        Tweagers are leading contributors to several open source projects — from
        functional programming languages to cross-platform frameworks.
      </Paragraph>
      <SimpleButton
        label="View all projects"
        url="/opensource"
        theme={{
          backgroundColor: `orange`,
          textColor: `white`,
          size: `big`,
        }}
      />
    </Flex>
    <Flex
      sx={{
        flexDirection: `column`,
        flexBasis: `20em`,
        maxWidth: `40em`,
        flexGrow: 1,
        gap: `3em`,
      }}
    >
      {projects.map(project => (
        <Project project={project} key={project.slug} />
      ))}
    </Flex>
  </Flex>
)

export default ProjectsSection
